import React from 'react';

import { Container } from 'components/shared/container';

import { Wrapper, Inner, StyledTitle, Description } from './static-page-content.styles';

export const Content = ({ title, content }) => (
  <Wrapper>
    <Container>
      <Inner>
        <StyledTitle as="h1">{title}</StyledTitle>
        <Description dangerouslySetInnerHTML={{ __html: content }} />
      </Inner>
    </Container>
  </Wrapper>
);
