import { graphql } from 'gatsby';
import React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Content } from 'components/pages/terms-of-use/content';

import { HiddenWidgetsStyle } from '../components/_settings/global-styles';
import { buildPageMetadata } from '../utils/data-builders';

const TermsOfUsePage = (props) => {
  const {
    data: {
      wpPage,
      wpPage: {
        acf: { showHeader, showFooter },
      },
    },
  } = props;

  const isShowHeader = showHeader === 'true';
  const isShowFooter = showFooter === 'true';

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <HiddenWidgetsStyle />
      {isShowHeader && <Header />}
      <Content title={wpPage.title} content={wpPage.content} />
      {isShowFooter && <Footer theme="light" />}
    </Layout>
  );
};

export default TermsOfUsePage;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      title
      content
      acf {
        showFooter
        showHeader
      }
    }
  }
`;
